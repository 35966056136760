@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
@import '_config.scss';

$utilityNameSpace: 'spa-data-table';
[data-sparta-container] {
  .#{$utilityNameSpace} {
    border-collapse: separate;
    border-spacing: 0;
    font-size: $type-size-16;
    line-height: $line-height-24;
    position: relative;
    table-layout: fixed;
    width: 100%;

    &--layout-auto {
      table-layout: auto;
    }

    &__title {
      font-family: $cnx-bold-family;
      font-size: $type-size-28;
      font-weight: $type-weight-bold;
      line-height: $line-height-36;
      text-align: left;
    }

    &__desc {
      color: $color-gray;
      font-size: $type-size-16;
      line-height: $line-height-24;
      text-align: left;
    }

    &__th,
    &__td {
      background-color: $color-brand-tertiary;
      color: $color-black;
      border-bottom: 1px solid $color-gray-s20;
      &--sticky {
        position: sticky;
        left: 0;
        z-index: $layer-level-1;
        &:after {
          content: ' ';
          position: absolute;
          top: 0;
          right: -25px;
          width: 25px;
          height: 100%;
          background: linear-gradient(to right,  rgba(0,0,0,0.12) 0%,rgba(255,255,255,0) 100%);
        }
      }
    }

    &__th {
      font-weight: $type-weight-bold;
      padding: $spacing-20 $spacing-16;
      text-align: left;
      vertical-align: bottom;

      &--sortable {
        padding: 0;

        button {
          .spa-icon {
            height: auto;
            width:  $spacing-44;
          }
        }
        button,
        button:hover,
        button:focus {
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 2px;
          color: inherit;
          font: inherit;
          height: auto;
          line-height: inherit;
          margin: 0;
          padding: $spacing-20 $spacing-44 $spacing-20 $spacing-16;
          text-align: inherit;
          width: 100%;

          > * {
            pointer-events: none;
          }
        }

        button {
          &:hover,
          &:focus {
            outline: 0;
            background-color: $color-gray-s05;

                        .spa-icon svg path {
              fill: $color-royal-one;
            }
          }
          .spa-icon--helper svg {
            height: $spacing-24;
            width: auto;
          }

          .spa-icon--helper--arrowUp {
            svg {
              height: $spacing-24;
              width:auto;
            }
          }

          .spa-data-table-icon--sort-direction,
          .spa-data-table-icon--sortable {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 19px;
            margin: auto;
          }

                .spa-data-table-icon--sort-direction {
            display: none;
            align-items:flex-end;

            svg {
              align-self: flex-end;
            }
          }

                .spa-data-table-icon--sortable {
            display: flex;
            align-items: flex-end;
          }

                &[data-sort-dir] {
            .spa-data-table-icon--sort-direction {
              display: flex;
            }
            .spa-data-table-icon--sortable {
              display: none;
            }

            &,
            &:hover,
            &:focus {
              &::after {
                background-color: $color-brand-secondary;
              }
            }
          }

                &[data-sort-dir="dsc"] {
            .spa-data-table-icon--sort-direction {
              transform: rotate(180deg);

                            svg {
                align-self: flex-start;
              }
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: -1px;
            width: calc(100% + 2px);
            height: $spacing-4;
            background-color: transparent;
          }

          &:hover,
          &:focus {
            &::after {
              background-color: $color-gray-s20;
            }
          }
        }
      }
    }

    &__td {
      padding: $spacing-20 $spacing-16;
      vertical-align: top;
      &--spinner {
        border-bottom: 0;
        height: 0;
        padding: 0;
      }

      &--spinner .spa-spinner--show-bdr,
      &--no-data-msg {
        max-width: 554px;
        width: 100%;
        margin: 34px auto 0;
        padding: 0;
      }

      &--no-data-msg {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--mono {
        font-family: $roboto-mono-family;
      }

      &--mono-md {
        font-family: $roboto-mono-medium-family;
      }

      &--mono-bl {
        font-family: $roboto-mono-bold-family;
      }

      &--up {
        color: $color-positive;
      }

      &--down {
        color: $color-negative;
      }
    }

    .#{$utilityNameSpace}__footer {
      .#{$utilityNameSpace}__td {
        font-weight: $type-weight-bold;
      }
    }

    &.#{$utilityNameSpace}--zebra-rows {
      tbody {
        tr:nth-child(even) {
          td, th {
            background-color: $color-gray-s05;
          }
        }
        tr:nth-child(odd) {
          td, th {
            background-color: $color-brand-tertiary;
          }
        }
      }
    }

    &.#{$utilityNameSpace}--text-size-small {
      font-size: $type-size-14;
      line-height: $line-height-20;

      .#{$utilityNameSpace}__title {
        font-size: $type-size-20;
        line-height: $line-height-28;
      }

      .#{$utilityNameSpace}__desc {
        color: $color-gray;
        font-size: $type-size-14;
        line-height: $line-height-20;
        text-align: left;
      }
    }

    &.#{$utilityNameSpace}--no-data {

      min-width: auto;
    }
  }

  .#{$utilityNameSpace}-container {
    position: relative;
    .spa-card--overlay {
      bottom: 0;
      box-shadow: none;
      left: 0;
      background-color: rgba($color-brand-tertiary, 0.78);
      position: absolute;
      right: 0;
      z-index: $layer-level-2;
      max-width: calc(100vw - 90px);
      min-height: 174px;
      top: 0;
      height: 100%;
    }

        .#{$utilityNameSpace}--no-data.#{$utilityNameSpace}-spinner {
      .#{$utilityNameSpace}__td {
        &--spinner {
          height: 175px; 
        }
      }
    }
  }

  .#{$utilityNameSpace}-container + .spa-pagination--align-center {
    position: relative;
    height: 40px;
    align-items: center;

    > .spa-pagination-btn > .spa-icon-loading {
      top: $spacing-8;
      margin-left: $spacing-8;
    }
  }

  .#{$utilityNameSpace}--text-size-small,
  .#{$utilityNameSpace}--small {
    font-size: $type-size-14;
    line-height: $line-height-20;

        .#{$utilityNameSpace} {
      &__th {
        padding: $spacing-16;

        &--sortable {
          padding: 0;

          button  {
            padding: $spacing-16 $spacing-40 $spacing-16 $spacing-16;

            .spa-data-table-icon--sortable {
              bottom: 14px;
            }

            .spa-data-table-icon--sort-direction {
              bottom: $spacing-16;
            }

            .spa-icon {
              height: auto;
              width: $spacing-44;
            }
            .spa-icon--helper svg {
              height: $spacing-24;
              width: auto;
            }

            .spa-icon--helper--arrowUp svg {
              height: $spacing-24;
              width: auto;
            }
          }
        }
      }
    }  
  }

  .#{$utilityNameSpace}--small {
    .#{$utilityNameSpace} {
      &__td {
        padding: $spacing-16 $spacing-16;
      }
    }
  }

  .#{$utilityNameSpace}--condensed {
    font-size: $type-size-14;
    line-height: $line-height-20;

    .#{$utilityNameSpace} {
      &__th {
        padding: $spacing-16;

        &--sortable {
          padding: 0;

          button  {
            padding: $spacing-8 $spacing-40 $spacing-8 $spacing-16;

            .spa-data-table-icon--sortable {
              bottom: 6px;
            }

            .spa-data-table-icon--sort-direction {
              bottom: $spacing-8;
            }

            .spa-icon {
              height: auto;
              width: $spacing-44;
            }
            .spa-icon--helper svg {
              height: $spacing-24;
              width: auto;
            }

            .spa-icon--helper--arrowUp svg {
              height: $spacing-24;
              width: auto;
            }
          }
        }
      }
      &__td {
        padding: $spacing-8 $spacing-16;
      }
    }
  }


  .#{$utilityNameSpace} {
    tbody {
      tr {
        &.new-record {
          &:focus {
            td {
              border-color: $color-royal-one;
              border-top: 1px solid $color-royal-one;

              &:first-child {
                border-left: 1px solid $color-royal-one;
              }

              &:last-child {
                border-right: 1px solid $color-royal-one;
              }
            }
          }
        }

        &.previous-record {
          td {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
}